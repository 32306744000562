<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <div class="mb15">
        <span class='mr15'>
          <a-button type="primary" v-has:agentAdd="()=> {$router.push('/agent/agent_list/detail?type=add')}">
            <PlusOutlined />
            添加代理商
          </a-button>
        </span>
      </div>
      <a-form ref='form' :model='search' :initialValues="search"
        :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="代理商" name='keywords'>
          <a-input v-model:value="search.keywords" :style="{width:'250px'}" placeholder="请输入代理商账号或公司名称"></a-input>
        </a-form-item>
        <a-form-item label="代理商级别" name='level_id'>
          <a-select v-model:value="search.level_id" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option v-for='item in levelList' :key="item.id" :value="item.id">
              {{item.level_name}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!--  -->
        <a-form-item label="代理商状态" >
          <a-select v-model:value="search.time_type"  :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              正常期
            </a-select-option>
            <!-- <a-select-option :value="0">
              禁止
            </a-select-option> -->
            <a-select-option :value="2">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="签约时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox m-t-20" :scroll="{ x: 'max-content'}">
           <template v-slot:signing_time="{ record }">
             <p>{{record.signing_start_time}}</p>
             <p>{{record.signing_end_time}}</p>
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <router-link :to="'/agent/agent_list/detail?type=look&id='+record.id">
                    <a href="javascript:;">详情</a>
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:agentEdit="()=> {$router.push('/agent/agent_list/detail?type=edit&id='+record.id)}" href="javascript:;">修改</a>
                </a-menu-item>
                 <a-menu-item>
                  <a v-authAction:agentDisable="()=>agentDisable(record)"  href="javascript:;">{{record.status?'禁用':'开启'}}</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:agentDel="()=>agentDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
        :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
        @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
</template>
<script>
  import { DownOutlined,PlusOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { getRouter } from "@/utils/app";
  import { setPageState, getPageState } from "@/utils/pageState";
  import { getAgentList,setAgentDisable,setAgentAuth,setAgentDel ,getAgentLevelList} from "@/api/agent";
  import { $iscode } from "@/utils/app";
  // 初始化默认筛选项数值
  let defSearch = {
    keywords: "",
    level_id: undefined,
    page: 1,
    limit: 10,
    signing_start_time: '',
    signing_end_time: '',
    time_type:''
  };
  export default {
    components: { DownOutlined,PlusOutlined },
    setup(props, ctx) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        time: [],
        total: 1,
        levelList:[]
      });
      let columns = [
        {
          title: "公司名称",
          dataIndex: "company",
          key: "company",
          width:240,
          ellipsis:true
        },
        {
          title: "代理商账号",
          dataIndex: "account",
          key: "account",
           width: 150,
        },
        {
          title: "代理商级别",
          dataIndex: "level_name",
          key: "level_name",
          width: 150,
        },
        {
          title: "账号余额",
          dataIndex: "balance",
          key: "balance",
          width: 150,
        },
        {
          title: "签约时间",
          dataIndex: "signing_time",
          key: "signing_time",
          slots: { customRender: "signing_time" },
          width:150,
        },
        {
          title: "状态",
          key: "status_text",
          dataIndex: "status_text",
          width: 120,
        },
        {
          title: "自定义域名",
          dataIndex: "domain",
          key: "domain",
          customRender: ({ text,record }) => {
            return record.domain ?record.domain:'-'
          }
        },
        {
          title: "自定义平台名",
          dataIndex: "platform_name",
          key: "platform_name",
          customRender: ({ text,record }) => {
            return record.platform_name ?record.platform_name:'-'
          }
        },
        {
          title: "自定义版权",
          dataIndex: "copyright",
          key: "copyright",
            customRender: ({ text,record }) => {
            return record.copyright ?record.copyright:'-'
          }
        },
        {
          title: "操作",
          key: "action",
           fixed: 'right',
          width: 100,
          slots: { customRender: "action" },
        },
      ];
      const $router = inject('$router')
      const $Modal = inject('$Modal')
      const $route = inject("$route");

      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, limit) => {
        search.value.page = 1;
        search.value.limit = limit;
        initData(search.value);
      };
      // 时间筛选
      const onChange = (e) => {
        if(e.length > 0){
          search.value.signing_start_time = e[0]
          search.value.signing_end_time = e[1]
        }else{
          search.value.signing_start_time = ''
          search.value.signing_end_time = ''
        }
      }
      //删除代理商
      const agentDel = (e) => {
        $Modal.confirm({
          content: '删除后该代理商将无法恢复，您确定要删除该代理商吗？',
          centered:true,
           onOk:async () => {
            let res = await setAgentDel(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            }else {
              message.error(res.msg);
            }
          }
        })
      }
      // 代理商状态修改
      const agentDisable = async (e) =>{
        var status = e.status ? 0:1
        let res = await setAgentDisable(e.id,status).then(res => res.data);
        if ($iscode(res,true)) {
        initData(search.value);
        }
      }
      // 代理商认证状态修改
      const agentAuth = async (e) =>{
        var status = e.is_certificate ? 1:0
        let res = await setAgentAuth(e.id,status).then(res => res.data);
        if ($iscode(res)) {
          message.success(res.msg);
        }else {
          message.error(res.msg);
        }
      }
      //代理商等级列表
      const getLevelList = async() =>{
        let res = await getAgentLevelList().then(res => res.data);
        if ($iscode(res)) {
          state.levelList = res.data.data
        }else {
          message.error(res.msg);
        }
      }
      //代理商列表
      const initData = async (values) => {
        state.listLoading = true;
        try {
           data.value=[]
          let res = await getAgentList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };


      onMounted(() => {
        if($route.query.type){
           search.value.time_type = Number($route.query.type)
        }
        // 查看是否有留存状态，有则替换
        let pageState = getPageState(getRouter().route.path);
        if (pageState) {
          search.value = Object.assign(search.value, pageState);
        }
        initData(search.value);
        getLevelList();
      });
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
        agentDel,
        agentDisable,
        agentAuth,
        getLevelList
      };
    },
  };
</script>